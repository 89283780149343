
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

import GapComponent from "./Gap.vue";

@Component({
  components: {
    GapComponent,
  },
  computed: {
    ...mapGetters("GapModule", ["getPublicGap", "getCategorieSelected"]),
  },
  head: {
    script: [{ type: "application/javascript", src: "../js/analytics.js" }],
  },
})
export default class ProjectComponent extends Vue {
  mounted() {
    this.$mixpanel.track(
      "Cumplimiento - " +
        this.$store.state.PolimorficModule.PolimorficState.framework_selected.name
    );
    this.$store.commit("GapModule/SET_VERSION_CATEGORIES", 0);
  }
}
