
import { Component, Vue, Watch, } from 'vue-property-decorator'
import { mapGetters, } from 'vuex'
import { format, } from 'date-fns'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"
import { GapInterface, } from '@/store/types/Analysis/GapAnalitic'

import DashboardStatusGraph from '@/components/DashboardStatusGraph/Index.vue'
import { ApplicationsInterface, } from '@/utils/Applications'
import ConfirmDialog from '@/components/ConfirmDialog/Index.vue'
import Unauthorized from '@/components/Unauthorized/index.vue'
import router from '@/router'

@Component({
  components: {
    DashboardStatusGraph,
    ConfirmDialog,OverlayBackground,
    Unauthorized
  },
  computed: {
    ...mapGetters('MetricsModule', ['getSelectedCats', 'getTotalGraph',]),
    ...mapGetters('PolimorficModule', ['getFrameworkSelected',]),
    UnauthorizedRole() {
      const role = this.$store.state.AuthModule.AuthState.role
      return !role.some((role: any) => role.includes("Cliente") || role.includes("Compliance") || role.includes("Implementador"));
    },
  },
})
export default class GapClientComponent extends Vue {
  private Business = this.$store.state.BusinessModule.BusinessState.businessClient
  private progressData = null
  private showMenu = false
  private confirm = false
  private renderComponent = true
  private applications: ApplicationsInterface[] = []
  private dateToVersions: string[] = []
  private date = format(new Date(), 'yyyy-MM-dd')
  private refreshDonut = false
  private framework = ''
  private loading = false;
  private unauthorized = false
  mounted () {
    localStorage.setItem(
      "lastComplianceOpen", this.$route.path
    )
    this.getData()
  }
  @Watch('$route.path')
  lastFrameworkPath (): void {
    localStorage.setItem(
      "lastComplianceOpen", this.$route.path
    )
  }
  @Watch('$route.params.id')
  changeFramework (): void {
    this.getData()
  }
  private open () {
    this.$mixpanel.track('Exportar analisis GAP', { 'Empresa': this.$store.state.BusinessModule.BusinessState.businessSelected.company.name, })
    this.showMenu = true
  }

  private out () {
    setTimeout(() => {
      this.showMenu = false
    }, 500)
  }

  private getData () {
    switch (Number(this.$route.params.id)) {
    case 1:
      this.framework = 'ISO 27002'
      break

    case 2:
      this.framework = 'ISO 27001'
      break

    case 3:
      this.framework = 'PCI DSS'
      break
    }

    this.$store.commit('PolimorficModule/SET_FRAMEWORK_SELECTED', {
      id: Number(this.$route.params.id),
      name: this.framework,
    })

    this.renderComponent = false
    this.refreshDonut = false    
    this.$store.dispatch('MetricsModule/getUncomplies')
    this.$store.dispatch('MetricsModule/getCompliesCat').then((data) => {
      this.$store.commit('GapModule/SET_GAP_CATEGORIES', data.data.category)
      this.$store.commit('SearchModule/SET_SEARCH_RESULTS', data.data.category)
      this.$store.commit('GapModule/SET_COMPLETED_INFO', data.data.compliance_summary.completed)
      this.$store.commit('GapModule/SET_PENDING_INFO', data.data.compliance_summary.pending)
      if (data.data.publish_gap_analysis) {
        this.$store.commit('GapModule/SET_PUBLIC_GAP', data.data.publish_gap_analysis)
      }
      if (data.data.publish_advance) {
        this.$store.commit('GapModule/SET_ADVANCE_GAP', data.data.publish_advance)
      }
      this.renderComponent = true
      this.refreshDonut = true
      this.unauthorized = false;
    }).catch((error) => {
      if (error.response.status === 403) {
        this.unauthorized = true;
      }
      if (error.response.status === 401) {
        router.push('/')
        location.reload()
      }
    })
    this.$store.dispatch('MetricsModule/getCompliesTotal')
  }

  // private getApps () {
  //   this.$store.dispatch('ApplicationModule/getApps').then((response) => {
  //     this.applications = response.data
  //   })
  // }

  private goToControl (element: GapInterface) {
    this.$store.commit('GapModule/SET_CONTROL_SELECTED', element)
    this.$router.push(`/startup/project/category/${element.id}`)
    this.$mixpanel.track('Categoria Normativa', { 'Titulo': element.name, })
    this.$emit('goControl', element.id)
  }

  private changeGapVersion (element: number) {
    this.$store.commit('GapModule/SET_VERSION_CATEGORIES', element)
    this.getData()
  }

  private exportGapAnalysis () {
    this.loading = true
    this.$store
      .dispatch('GapModule/exportGap', this.Business.id)
      .then(() => {
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }
}

